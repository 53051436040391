import { FC } from 'react';
import Script from 'next/script';

const TopBanner: FC = () => {
  return (
    <>
      {/* Yandex.RTB R-A-745629-3 */}
      <div id='yandex_rtb_R-A-745629-3'></div>
      <Script strategy='lazyOnload'>
        {`
          window.yaContextCb.push(()=>{
          Ya.Context.AdvManager.render({
          renderTo: 'yandex_rtb_R-A-745629-3',
          blockId: 'R-A-745629-3'})})
        `}
      </Script>
    </>
  );
};

export default TopBanner;
