import { FC, PropsWithChildren } from 'react';
import Head from 'next/head';
import { IMeta } from '../../interfaces/meta.interface';

const Meta: FC<PropsWithChildren<IMeta>> = ({ title, description, pageHref, canonicalHref, cover, robotsNoFollow, children }) => {
  return (
    <>
      <Head>
        <script async type="text/javascript" src="/static/google-tag-manager.js"></script>
        <script type="text/javascript" src="/static/yandex-context-cb.js"></script>
        <script src="https://yandex.ru/ads/system/context.js" async />
        <title>{title}</title>
        <meta name="google-site-verification" content="5eeEb7fjWP_DovfF4dSVkfyARICaNMj5ihp1yKjU6EA" />
        <meta name="yandex-verification" content="1e9abfe09e7b557c" />
        <meta name="description" content={description} />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_SITE_URL}${pageHref}`} />
        {!!robotsNoFollow ? <meta name="robots" content="noindex, nofollow" /> : ``}
        <link rel="canonical" href={`${process.env.NEXT_PUBLIC_SITE_URL}${canonicalHref}`} />
        {cover ? (
          <>
            <meta property="og:type" content="books.book" />
            <meta property="og:image" content={cover}></meta>
          </>
        ) : (
          <>
            <meta property="og:image" content="/logo-desktop.svg"></meta>
          </>
        )}
        <link rel="icon" type="image/svg" href="/favicon/favicon.svg" />
        <link rel="shortcut icon" href="/favicon/favicon.ico" />
      </Head>
      <noscript>
        <iframe loading="lazy" src="https://www.googletagmanager.com/ns.html?id=GTM-W7FR46P" height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>
      </noscript>
      {children}
    </>
  );
};

export default Meta;
